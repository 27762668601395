<template>
  <section>
    <script v-html="doGenerateCourse(course.name, course.subtitle, course.organization.name, course.organization.homepage)" type="application/ld+json"></script>
    <v-container>
      <v-progress-circular v-if="isLoading" indeterminate color="white"></v-progress-circular>
      <v-breadcrumbs
        :items="breadcrumbs"
        divider="/"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :disabled="item.disabled"
            class="show-pointer gothic-1"
            @click="$router.replace({ name: item.goTo })"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div>
        <v-avatar size="58" class="ma-2">
          <v-img contain :src="course.organization.logoUrl" :alt="course.organization.name"></v-img>
        </v-avatar>
        <span class="font-weight-bold">{{ course.organization.name }}</span>
      </div>
      <v-row>
        <v-col xl="7" lg="8" md="7" sm="12" xs="12" offset-xl="1">
          <span class="text-center goBold-4">{{ course.name }}</span>
          <v-carousel hide-delimiters :cycle="true" :show-arrows="true" class="mt-6 rounded-lg mx-auto" style="height: 500px">
            <template v-slot:prev="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              ><font-awesome-icon :icon="['fas', 'arrow-left']"/></v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              ><font-awesome-icon :icon="['fas', 'arrow-right']"/></v-btn>
            </template>
            <v-carousel-item v-for="(image, index) in course.images" :src="image.url" :aria-label="course.name" :key="index"></v-carousel-item>
          </v-carousel>
          <div v-html="course.content" class="my-6 main-content-view"></div>
        </v-col>
        <v-col xl="3" lg="4" md="5" sm="12" xs="12">
          <div class="sticky">
            <div v-show="$store.getters.doGetSystemControlAllowPurchases">
              <v-btn block color="primary" :disabled="!course.isActive" @click="doAddToCart">
                <font-awesome-icon size="lg" :icon="['fas', 'cart-plus']" fixed-width class="mr-2"></font-awesome-icon>
                Add to Cart - ${{ course.cents / 100 }}
              </v-btn>
              <div v-if="!course.isActive" class="mt-3 font-weight-bold text-center">
                <v-card>
                  <v-card-text>
                    <div class="gothic-3">
                      <font-awesome-icon size="lg" :icon="['fas', 'triangle-exclamation']" fixed-width class="warning--text"></font-awesome-icon>
                      This course is currently unavailable
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div class="mt-4">
              <v-card outlined flat>
                <v-card-text>
                  <div class="mt-1" v-if="Object.keys(groupedEvents).length >= 1">
                    <v-expansion-panels class="mt-2">
                      <v-expansion-panel v-for="(groupUuid, indexA) in Object.keys(groupedEvents)" :key="indexA">
                        <v-expansion-panel-header>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $moment(groupedEvents[groupUuid][0].start).format('MMM, DD YYYY') }}
                                  <span v-if="groupedEvents[groupUuid].length > 1">to {{ $moment(groupedEvents[groupUuid][(groupedEvents[groupUuid].length - 1)].start).format('MMM, DD YYYY') }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="groupedEvents[groupUuid].length > 1">
                                  <span>{{ groupedEvents[groupUuid].length }} classes scheduled</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                            <v-divider></v-divider>
                            <span v-for="(event, indexB) in groupedEvents[groupUuid]" :key="indexB">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  {{ $moment(event.start).format('llll') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                                  <span class="ml-2">{{ event.location.name }}</span>
                                  <v-tooltip v-if="event.optional" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span class="ml-1" v-bind="attrs" v-on="on">
                                      ( Optional <font-awesome-icon size="sm" :icon="['fas', 'circle-info']" fixed-width></font-awesome-icon> )
                                      </span>
                                    </template>
                                    <div>
                                      You can choose to join another <br> {{ event.location.name }} session if this date<br> and time does not work for you.
                                    </div>
                                  </v-tooltip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                          </span>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider></v-divider>
                  </div>
                  <div class="my-3">
                    <v-btn text tile outlined block @click="doCallBusiness" aria-label="Call for Questions">
                      <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
                      <span class="ml-2">Call for questions</span>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="my-5 block">
              <v-card outlined>
                <v-card-text>
                  {{ course.subtitle }}
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <wave-effect></wave-effect>
  </section>
</template>

<script>

import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'viewCourse',
  components: { WaveEffect },
  data: () => ({
    isLoading: false,
    breadcrumbs: [
      {
        text: 'Salt and Sea Scuba',
        disabled: false,
        goTo: 'home'
      },
      {
        text: 'Courses',
        disabled: false,
        goTo: 'courses'
      }
    ],
    course: {
      id: 0,
      name: String(),
      organization: {
        name: String(),
        logoUrl: String(),
        homepage: String()
      },
      queueRequired: false
    },
    groupedEvents: [],
    productGroupUuid: String()
  }),
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.course.doRead(this.course.id).then(course => {
        if (course === null) {
          this.$router.replace({ name: 'home' })
        } else if (!course.isPublic) {
          this.$router.replace({ name: 'home' })
        } else {
          this.course = course

          this.breadcrumbs.push({
            text: course.name,
            disabled: true
          })

          this.doSetDocumentTitle(course.name, true)

          // this.doLoadCourseEvents()
        }
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doLoadCourseEvents () {
      this.groupedEvents = {}

      this.$services.course.event.doReadFromCourseIdAndGatherGroups(this.course.id, new Date()).then(groupedEvents => {
        this.groupedEvents = groupedEvents
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    doRenderMeta () {
      this.$meta().inject()
    },
    doAddToCart () {
      const productGroup = {
        id: this.course.id,
        uuid: this.productGroupUuid,
        title: this.course.name,
        description: this.course.subtitle,
        images: this.course.images,
        type: 'course',
        products: [this.course],
        properties: {}
      }

      this.$store.dispatch('doAddProductGroup', productGroup)

      this.productGroupUuid = this.$uuid.v4()
    }
  },
  mounted () {
    this.course.id = this.$route.params.id
    this.doLoad()
    this.productGroupUuid = this.$uuid.v4()
  }
}
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 100px;
}

::v-deep .main-content-view img {
  width: 100%;
  height: 100%;
}
</style>
